import React from 'react'
import { Link, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import { Footer } from '../components/footer'
import styled from 'styled-components'
import sbornik from './pdf/Сборник материалов.pdf'
import konspekt from './pdf/Конспект практического занятия тренинга.pdf'
import masterKlass1 from './pdf/Мастер-класс Изучай, действуй, сохраняй социокультурные проекты и инициативы.pdf'
import masterKlass2 from './pdf/Мастер-класс С чего начинается реставрация.pdf'
import prez1 from './pdf/Презентация Документация реставратора.pdf'
import prez2 from './pdf/Презентация лекции Культурное наследие как предмет соцкульт проектир.pdf'
import prez3 from './pdf/Презентация лекции о Социокультурном проектировании и методиках проф самоопределения.pdf'
import prez4 from './pdf/Презентация лекции Об основах реставраци.pdf'
import prez5 from './pdf/Презентация лекции по социокультурному проектированию.pdf'
import klass10 from './pdf/part2/10 класс.pdf'
import klass11 from './pdf/part2/11 класс.pdf'
import programma from './pdf/part2/20-1-032292 ПРОГРАММА ОБУЧАЮЩЕГО КУРСА.pdf'
import konspekt2 from './pdf/part2/Конспект лекции Наследие дело каждого.pdf'
import konspekt3 from './pdf/part2/Краткий конспект занятия по археологии.pdf'
import masterKlass3 from './pdf/part2/Мастер-класс О мемориальном комплексе Концлагерь Красный.pdf'
import methodical1 from './pdf/part2/Методические рекомендации для проведения занятий.pdf'
import posobie from './pdf/part2/Пособие для учителя.pdf'
import prezNasledie from './pdf/part2/Презентация лекции _Наследие - дело каждого.pdf'

const LayoutCatalog = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: end;
  position: relative;
  width: 100%;
  max-width: 78rem;
  margin: 0 auto;
  padding: 30px 1rem;
  /* background: #f2f1e2;
  border-radius: 5px;
  box-shadow: 15px 10px 40px -2px rgba(0, 0, 0, 0.8); */

  .about_wrapper {
    width: 100%;
    max-width: 580px;
  }

  h1 {
    font-family: 'GT Walsheim Pro Bold';
  }

  ul {
    padding: 0;
    list-style-type: none;
  }

  .about_images {
    margin-bottom: 10vw;
  }

  a:hover {
    opacity: 0.8;
  }
`

const Icon = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  & > span {
    padding-left: 10px;
  }

  .icon {
    display: inline-block;
    min-width: 40px;
    width: 40px;
    height: 50px;
    border-radius: 2px;
    cursor: pointer;
    position: relative;
    margin: 2px 5px;
  }
  .icon::after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-radius: 0 2px;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .icon--pdf {
    background-color: #db4437;
  }
  .icon--pdf::after {
    background: linear-gradient(45deg, #e78178 50%, #fff 50%);
  }
  .icon--pdf i::before {
    content: '☵';
  }

  .icon i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: block;
    font-size: 10px;
    color: #fff;
    font-weight: 500;
  }
  .icon i::before,
  .icon i::after {
    display: block;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }
  .icon i::before {
    text-align: center;
    font-size: 12px;
    opacity: 0;
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  .icon i::after {
    content: attr(title);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  &:hover .icon {
    border-radius: 2px 4px 2px 2px;
  }
  &:hover .icon::after {
    width: 12px;
    height: 12px;
  }
  &:hover .icon i::before {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  &:hover .icon i::after {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`

class MethodicalPage extends React.Component {
  render() {
    return (
      <Layout location="/methodical">
        <LayoutCatalog>
          <Helmet title="Карьерный навигатор в сфере культурного наследия" />
          <div className="about_wrapper">
            <h1>Методические материалы</h1>
            <Icon href={klass10} target="_blank" rel="noopener noreferrer">
              <div className="icon icon--pdf">
                <i title="pdf"></i>
              </div>
              <span>МОЙ ИНДИВИДУАЛЬНЫЙ ПРОЕКТ учебно-методическое пособие по социокультурному проектированию для обучающихся 10 класса.pdf</span>
            </Icon>
            <Icon href={klass11} target="_blank" rel="noopener noreferrer">
              <div className="icon icon--pdf">
                <i title="pdf"></i>
              </div>
              <span>МОЙ ИНДИВИДУАЛЬНЫЙ ПРОЕКТ учебно-методическое пособие по социокультурному проектированию для обучающихся 11 класса.pdf</span>
            </Icon>
            <Icon href={posobie} target="_blank" rel="noopener noreferrer">
              <div className="icon icon--pdf">
                <i title="pdf"></i>
              </div>
              <span>ИНДИВИДУАЛЬНЫЙ ПРОЕКТ В СОВРЕМЕННОЙ ШКОЛЕ учебно-методическое пособие по социокультурному проектированию для учителя.pdf</span>
            </Icon>
            <Icon href={sbornik} target="_blank" rel="noopener noreferrer">
              <div className="icon icon--pdf">
                <i title="pdf"></i>
              </div>
              <span>Сборник материалов.pdf</span>
            </Icon>
            <Icon href={konspekt} target="_blank" rel="noopener noreferrer">
              <div className="icon icon--pdf">
                <i title="pdf"></i>
              </div>
              <span>Конспект практического занятия тренинга.pdf</span>
            </Icon>
            <Icon href={masterKlass1} target="_blank" rel="noopener noreferrer">
              <div className="icon icon--pdf">
                <i title="pdf"></i>
              </div>
              <span>Мастер-класс Изучай, действуй, сохраняй социокультурные проекты и инициативы.pdf</span>
            </Icon>
            <Icon href={masterKlass2} target="_blank" rel="noopener noreferrer">
              <div className="icon icon--pdf">
                <i title="pdf"></i>
              </div>
              <span>Мастер-класс С чего начинается реставрация.pdf</span>
            </Icon>
            <Icon href={prez1} target="_blank" rel="noopener noreferrer">
              <div className="icon icon--pdf">
                <i title="pdf"></i>
              </div>
              <span>Презентация Документация реставратора.pdf</span>
            </Icon>
            <Icon href={prez2} target="_blank" rel="noopener noreferrer">
              <div className="icon icon--pdf">
                <i title="pdf"></i>
              </div>
              <span>Презентация лекции Культурное наследие как предмет соцкульт проектир.pdf</span>
            </Icon>
            <Icon href={prez3} target="_blank" rel="noopener noreferrer">
              <div className="icon icon--pdf">
                <i title="pdf"></i>
              </div>
              <span>Презентация лекции о Социокультурном проектировании и методиках проф самоопределения.pdf</span>
            </Icon>
            <Icon href={prez4} target="_blank" rel="noopener noreferrer">
              <div className="icon icon--pdf">
                <i title="pdf"></i>
              </div>
              <span>Презентация лекции Об основах реставраци.pdf</span>
            </Icon>
            <Icon href={prez5} target="_blank" rel="noopener noreferrer">
              <div className="icon icon--pdf">
                <i title="pdf"></i>
              </div>
              <span>Презентация лекции по социокультурному проектированию.pdf</span>
            </Icon>
            <Icon href={programma} target="_blank" rel="noopener noreferrer">
              <div className="icon icon--pdf">
                <i title="pdf"></i>
              </div>
              <span>20-1-032292 ПРОГРАММА ОБУЧАЮЩЕГО КУРСА.pdf</span>
            </Icon>  
            <Icon href={konspekt2} target="_blank" rel="noopener noreferrer">
              <div className="icon icon--pdf">
                <i title="pdf"></i>
              </div>
              <span>Конспект лекции Наследие дело каждого.pdf</span>
            </Icon>
            <Icon href={konspekt3} target="_blank" rel="noopener noreferrer">
              <div className="icon icon--pdf">
                <i title="pdf"></i>
              </div>
              <span>Краткий конспект занятия по археологии.pdf</span>
            </Icon>
            <Icon href={masterKlass3} target="_blank" rel="noopener noreferrer">
              <div className="icon icon--pdf">
                <i title="pdf"></i>
              </div>
              <span>Мастер-класс О мемориальном комплексе Концлагерь Красный.pdf</span>
            </Icon>
            <Icon href={methodical1} target="_blank" rel="noopener noreferrer">
              <div className="icon icon--pdf">
                <i title="pdf"></i>
              </div>
              <span>Методические рекомендации для проведения занятий.pdf</span>
            </Icon>
            <Icon href={prezNasledie} target="_blank" rel="noopener noreferrer">
              <div className="icon icon--pdf">
                <i title="pdf"></i>
              </div>
              <span>Презентация лекции _Наследие - дело каждого.pdf</span>
            </Icon>
          </div>
        </LayoutCatalog>
        <Footer
          text="При финансовой поддержке Фонда президентских грантов, проект №
          20-1-032292"
        />
      </Layout>
    )
  }
}

export default MethodicalPage
